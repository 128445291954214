import { MachineConfig, StateSchema, createMachine } from "xstate";
import { PaymentType } from "../src/fleets/fleet.entity";
import { LatLng, Point, Service } from "../src/requests/request.entity";
import { WorkflowType } from "./workflow.types";
import { capitalizeCamelcaseString } from "./utils/string.utils";
import {
  isSaaS,
  wasEstimateSkipped,
  isEstimateApproved,
} from "./saas/saas.shop.guards";

/*
  A class for throwing errors when an invalid transition is attempted. This ensures that we
  provide consistent error messages and provides a statusCode that nestjs can use instead of
  getting an internal server error 5xx. I don't want to add nestjs exceptions here since
  this file could be used in lots of places that don't wanna pull in nestjs as a dependency.

  From the nest docs https://docs.nestjs.com/exception-filters:
  > Basically, any thrown exception containing the statusCode and message properties will be properly populated and sent back as a response (instead of the default InternalServerErrorException for unrecognized exceptions)
*/
export class InvalidTransitionError extends Error {
  public readonly statusCode = 400;

  constructor(
    public readonly request: RequestContext,
    public readonly transition: RequestOption
  ) {
    super(
      `Invalid transition from ${capitalizeCamelcaseString(
        request.status
      )} to ${capitalizeCamelcaseString(transition)}`
    );
  }
}

export interface RequestContext {
  id?: number;
  status: RequestState;
  shopId?: number | null;
  fleetId?: number | null;
  paymentType?: PaymentType;
  startPickupDate?: Date | string | number | null;
  endPickupDate?: Date | string | number | null;
  pickupAddress?: string | null;
  pickupLatLon?: Point | LatLng | null;
  shopECD?: Date | string | number | null;
  wasEstimateSkipped?: boolean;
  workflowType?: string | null;
  services?:
    | {
        serviceItems: {
          type: string;
        }[];
        approved?: boolean | null;
      }[]
    | Service[];
  additionalWorkRecommended?: boolean;
}

export enum RequestState {
  NEW = "new",
  SHOP_PROPOSED_TIMES = "shopProposedTimes",
  ASSIGNED = "assigned",
  AWAITING_APPROVAL = "awaitingApproval",
  DECLINED = "declined",
  APPROVED = "approved",
  AWAITING_SHOP_ACCEPTANCE = "awaitingShopAcceptance",
  AWAITING_SHOP_ASSIGNMENT = "awaitingShopAssignment",
  SCHEDULED_PICKUP = "scheduledPickup",
  IN_SHOP = "inShop",
  IN_SHOP_AWAITING_APPROVAL = "inShopAwaitingApproval",
  IN_PROGRESS = "inProgress",
  AWAITING_PAYMENT = "awaitingPayment",
  INSURANCE_INVOICE_APPROVED = "insuranceInvoiceApproved",
  INVOICE_APPROVED = "invoiceApproved",
  PAID = "paid",
  SCHEDULED_DROPOFF = "scheduledDropoff",
  DELIVERY_IN_PROGRESS = "deliveryInProgress",
  CLOSED = "closed",
  LOST = "lost",
}

export enum RequestAction {
  SHARE_ESTIMATE = "shareEstimate",
  APPROVE_ESTIMATE = "approveEstimate",
  SCHEDULE_PICKUP = "schedulePickup",
  SCHEDULE_DROPOFF = "scheduleDropoff",
  ACCEPT_SHOP_ASSIGNMENT = "acceptShopAssignment",
  REJECT_SHOP_ASSIGNMENT = "rejectShopAssignment",
  DECLINE_ESTIMATE = "declineEstimate",
  SET_IN_SHOP = "setInShop",
  SET_IN_PROGRESS = "setInProgress",
  ADD_DIAGNOSTICS = "addDiagnostics",
  ADD_PARTS_UPDATE = "addPartsUpdate",
  ADD_NEW_SUPPLEMENT = "addNewSupplement",
  SET_WORK_COMPLETED = "setWorkCompleted",
  SET_PAID = "setPaid",
  UPDATE_ECD = "updateEcd",
  UPDATE_SHOP_INVOICE = "updateShopInvoice",
  CLOSE_REQUEST = "closeRequest",
  ASSIGN_SHOP = "assignShop",
  APPROVE_INVOICE = "approveInvoice",
  SET_DELIVERY_IN_PROGRESS = "setDeliveryInProgress",
  REJECT_NEW_SUPPLEMENT = "rejectNewSupplement",
  ACCEPT_NEW_SUPPLEMENT = "acceptNewSupplement",
}

export const REQUEST_STATE_STRINGS: string[] = Object.values(RequestState).map(
  (s) => s.toString()
);
export function isRequestState(status: string): status is RequestState {
  return REQUEST_STATE_STRINGS.includes(status);
}

export function convertStringToRequestState(
  status: string
): RequestState | null {
  if (!isRequestState(status)) {
    return null;
  }

  return (
    Object.values(RequestState).find((s) => s.toString() === status) || null
  );
}

export type RequestOption = RequestAction | RequestState;

export const CLOSED_STATES = [
  RequestState.LOST,
  RequestState.CLOSED,
  RequestState.DECLINED,
];

interface RequestStateSchema extends StateSchema<RequestStateSchema> {
  states: { [key in RequestState]: StateSchema<RequestStateSchema> };
}

export type RequestEvent = { type: RequestOption };

function isShopAssigned(context: RequestContext, _event?: RequestEvent) {
  return Boolean(context.shopId);
}

export function isPickupScheduled(
  context: RequestContext,
  _event?: RequestEvent
) {
  const isSaaS = context.workflowType === WorkflowType.SAAS;
  const checkDates = Boolean(context.startPickupDate && context.endPickupDate);
  return Boolean(
    checkDates &&
      (isSaaS || (!isSaaS && context.pickupAddress && context.pickupLatLon))
  );
}

export function isFmcRequest(
  context: Pick<RequestContext, "fleetId" | "paymentType">
) {
  return Boolean(
    context.fleetId &&
      context.paymentType &&
      [PaymentType.MANAGEMENT_COMPANY, PaymentType.INVOICING].includes(
        context.paymentType
      )
  );
}

export function isInsurance(context: RequestContext) {
  return context.workflowType === WorkflowType.Insurance;
}

export const isAdditionalWorkRecommended = (context: RequestContext) => {
  return !!context.additionalWorkRecommended;
};

const config: MachineConfig<RequestContext, RequestStateSchema, RequestEvent> =
  {
    id: "request",
    initial: RequestState.NEW,
    predictableActionArguments: true,
    states: {
      new: {
        on: {
          assigned: RequestState.ASSIGNED,
          lost: RequestState.LOST,
          awaitingShopAcceptance: RequestState.AWAITING_SHOP_ACCEPTANCE,
          awaitingShopAssignment: RequestState.AWAITING_SHOP_ASSIGNMENT,
          assignShop: {
            target: RequestState.NEW,
            cond: (context) => !isSaaS(context),
          },
        },
      },
      shopProposedTimes: {
        on: {
          scheduledPickup: RequestState.SCHEDULED_PICKUP,
          new: RequestState.NEW,
        },
      },
      assigned: {
        on: {
          approved: RequestState.APPROVED,
          awaitingApproval: RequestState.AWAITING_APPROVAL,
          shareEstimate: RequestState.AWAITING_APPROVAL,
          lost: RequestState.LOST,
          awaitingShopAcceptance: RequestState.AWAITING_SHOP_ACCEPTANCE,
          awaitingShopAssignment: RequestState.AWAITING_SHOP_ASSIGNMENT,
          updateEcd: RequestState.ASSIGNED,
          assignShop: {
            target: RequestState.ASSIGNED,
            cond: (context) => !isSaaS(context),
          },
          addPartsUpdate: RequestState.ASSIGNED,
        },
      },
      awaitingApproval: {
        on: {
          approved: RequestState.APPROVED,
          declined: RequestState.DECLINED,
          lost: RequestState.LOST,
          inShopAwaitingApproval: RequestState.IN_SHOP_AWAITING_APPROVAL,
          awaitingShopAssignment: RequestState.AWAITING_SHOP_ASSIGNMENT,
          awaitingShopAcceptance: RequestState.AWAITING_SHOP_ACCEPTANCE,
          shareEstimate: RequestState.AWAITING_APPROVAL,
          declineEstimate: [
            {
              target: RequestState.DECLINED,
              cond: (context) => !isSaaS(context),
            },
            {
              target: RequestState.ASSIGNED,
              cond: (context) =>
                isSaaS(context) && !isEstimateApproved(context),
            },
            {
              target: RequestState.APPROVED,
              cond: (context) => isSaaS(context) && isEstimateApproved(context),
            },
          ],
          approveEstimate: [
            {
              target: RequestState.SCHEDULED_PICKUP,
              cond: (context) => isSaaS(context) && isPickupScheduled(context),
            },
            {
              target: RequestState.APPROVED,
              cond: (context) => isSaaS(context) && !isPickupScheduled(context),
            },
            {
              target: RequestState.AWAITING_SHOP_ASSIGNMENT,
              cond: (context) => {
                return isPickupScheduled(context) && !isShopAssigned(context);
              },
            },
            {
              target: RequestState.AWAITING_SHOP_ACCEPTANCE,
              cond: (context) => {
                return isPickupScheduled(context) && isShopAssigned(context);
              },
            },
            {
              target: RequestState.APPROVED,
            },
          ],
          updateEcd: RequestState.AWAITING_APPROVAL,
          schedulePickup: RequestState.SCHEDULED_PICKUP,
          scheduledPickup: {
            target: RequestState.SCHEDULED_PICKUP,
            cond: "isPickupScheduled",
          },
          assignShop: {
            target: RequestState.AWAITING_APPROVAL,
            cond: (context) => !isSaaS(context),
          },
          addPartsUpdate: RequestState.AWAITING_APPROVAL,
        },
      },
      declined: {
        on: {
          awaitingApproval: RequestState.AWAITING_APPROVAL,
          shareEstimate: RequestState.AWAITING_APPROVAL,
          lost: RequestState.LOST,
          inShop: RequestState.IN_SHOP,
        },
      },
      approved: {
        on: {
          awaitingShopAcceptance: [
            {
              target: RequestState.AWAITING_SHOP_ACCEPTANCE,
              cond: "isShopAssigned",
            },
          ],
          awaitingShopAssignment: RequestState.AWAITING_SHOP_ASSIGNMENT,
          lost: RequestState.LOST,
          awaitingApproval: RequestState.AWAITING_APPROVAL,
          shareEstimate: RequestState.AWAITING_APPROVAL,
          schedulePickup: [
            {
              target: RequestState.SCHEDULED_PICKUP,
              cond: "isSaaS",
            },
            {
              target: RequestState.AWAITING_SHOP_ACCEPTANCE,
              cond: "isShopAssigned",
            },
            {
              target: RequestState.AWAITING_SHOP_ASSIGNMENT,
            },
          ],
          updateEcd: RequestState.APPROVED,
          scheduledPickup: {
            target: RequestState.SCHEDULED_PICKUP,
            cond: "isPickupScheduled",
          },
          assignShop: {
            target: RequestState.APPROVED,
            cond: (context) => !isSaaS(context),
          },
          addPartsUpdate: RequestState.APPROVED,
        },
      },
      awaitingShopAcceptance: {
        on: {
          scheduledPickup: RequestState.SCHEDULED_PICKUP,
          awaitingShopAssignment: RequestState.AWAITING_SHOP_ASSIGNMENT,
          awaitingApproval: RequestState.AWAITING_APPROVAL,
          shareEstimate: RequestState.AWAITING_APPROVAL,
          addPartsUpdate: RequestState.AWAITING_SHOP_ACCEPTANCE,
          acceptShopAssignment: [
            {
              target: RequestState.ASSIGNED,
              cond: (context) =>
                isSaaS(context) &&
                isShopAssigned(context) &&
                !wasEstimateSkipped(context),
            },
            {
              target: RequestState.SCHEDULED_PICKUP,
              cond: (context) =>
                isSaaS(context) &&
                isShopAssigned(context) &&
                wasEstimateSkipped(context) &&
                isPickupScheduled(context),
            },
            {
              target: RequestState.APPROVED,
              cond: (context) =>
                isSaaS(context) &&
                isShopAssigned(context) &&
                wasEstimateSkipped(context),
            },
            {
              target: RequestState.SCHEDULED_PICKUP,
              cond: (context) =>
                !isSaaS(context) &&
                isShopAssigned(context) &&
                isPickupScheduled(context),
            },
            {
              target: RequestState.APPROVED,
              cond: (context) =>
                !isSaaS(context) &&
                isShopAssigned(context) &&
                !isPickupScheduled(context),
            },
          ],
          assigned: RequestState.ASSIGNED,
          approved: RequestState.APPROVED,
          rejectShopAssignment: RequestState.AWAITING_SHOP_ASSIGNMENT,
          schedulePickup: RequestState.AWAITING_SHOP_ACCEPTANCE,
          lost: RequestState.LOST,
          updateEcd: RequestState.AWAITING_SHOP_ACCEPTANCE,
          new: RequestState.NEW,
          assignShop: {
            target: RequestState.AWAITING_SHOP_ACCEPTANCE,
            cond: (context) => !isSaaS(context),
          },
        },
      },
      awaitingShopAssignment: {
        on: {
          awaitingShopAcceptance: [
            {
              target: RequestState.AWAITING_SHOP_ACCEPTANCE,
              cond: "isShopAssigned",
            },
          ],
          awaitingApproval: RequestState.AWAITING_APPROVAL,
          shareEstimate: RequestState.AWAITING_APPROVAL,
          schedulePickup: RequestState.AWAITING_SHOP_ASSIGNMENT,
          lost: RequestState.LOST,
          updateEcd: RequestState.AWAITING_SHOP_ASSIGNMENT,
          new: RequestState.NEW,
          approved: RequestState.APPROVED,
          addPartsUpdate: RequestState.AWAITING_SHOP_ASSIGNMENT,
          assignShop: [
            {
              target: RequestState.AWAITING_SHOP_ACCEPTANCE,
              cond: (context) => isSaaS(context),
            },
            {
              target: RequestState.APPROVED,
              cond: (context) =>
                !isSaaS(context) &&
                isShopAssigned(context) &&
                wasEstimateSkipped(context) &&
                !isPickupScheduled(context),
            },
            {
              target: RequestState.AWAITING_SHOP_ACCEPTANCE,
              cond: (context) =>
                !isSaaS(context) &&
                isShopAssigned(context) &&
                wasEstimateSkipped(context) &&
                isPickupScheduled(context),
            },
            {
              target: RequestState.APPROVED,
              cond: (context) =>
                !isSaaS(context) &&
                isShopAssigned(context) &&
                !wasEstimateSkipped(context) &&
                !isPickupScheduled(context),
            },
            {
              target: RequestState.AWAITING_SHOP_ACCEPTANCE,
              cond: (context) =>
                !isSaaS(context) &&
                isShopAssigned(context) &&
                !wasEstimateSkipped(context) &&
                isPickupScheduled(context),
            },
            {
              target: RequestState.AWAITING_SHOP_ASSIGNMENT,
              cond: (context) => !isSaaS(context) && !isShopAssigned(context),
            },
          ],
        },
      },
      scheduledPickup: {
        on: {
          awaitingApproval: RequestState.AWAITING_APPROVAL,
          inShop: RequestState.IN_SHOP,
          lost: RequestState.LOST,
          schedulePickup: RequestState.SCHEDULED_PICKUP,
          setInShop: RequestState.IN_SHOP,
          updateEcd: RequestState.SCHEDULED_PICKUP,
          assignShop: {
            target: RequestState.SCHEDULED_PICKUP,
            cond: (context) => !isSaaS(context),
          },
          approved: RequestState.APPROVED, //for when a pickup is cancelled
          addPartsUpdate: RequestState.SCHEDULED_PICKUP,
        },
      },
      inShopAwaitingApproval: {
        on: {
          inShop: RequestState.IN_SHOP,
          addPartsUpdate: RequestState.IN_SHOP_AWAITING_APPROVAL,
          shareEstimate: RequestState.IN_SHOP_AWAITING_APPROVAL,
          declineEstimate: RequestState.IN_SHOP,
          approveEstimate: RequestState.IN_SHOP,
          addNewSupplement: RequestState.IN_SHOP_AWAITING_APPROVAL,
          updateEcd: RequestState.IN_SHOP_AWAITING_APPROVAL,
          assignShop: {
            target: RequestState.IN_SHOP_AWAITING_APPROVAL,
            cond: (context) => !isSaaS(context),
          },
        },
      },
      inShop: {
        on: {
          inProgress: RequestState.IN_PROGRESS,
          inShopAwaitingApproval: RequestState.IN_SHOP_AWAITING_APPROVAL,
          awaitingPayment: RequestState.AWAITING_PAYMENT,
          shareEstimate: RequestState.IN_SHOP_AWAITING_APPROVAL,
          paid: RequestState.PAID,
          invoiceApproved: [
            {
              target: RequestState.INVOICE_APPROVED,
              cond: "isFmcRequest",
            },
          ],
          addDiagnostics: [
            {
              target: RequestState.IN_PROGRESS,
              cond: (context) => !isAdditionalWorkRecommended(context),
            },
            {
              target: RequestState.IN_SHOP,
              cond: (context) => isAdditionalWorkRecommended(context),
            },
          ],
          addPartsUpdate: RequestState.IN_SHOP,
          addNewSupplement: RequestState.IN_SHOP,
          setInProgress: RequestState.IN_PROGRESS,
          setWorkCompleted: RequestState.IN_SHOP,
          updateEcd: RequestState.IN_SHOP,
          acceptNewSupplement: RequestState.IN_PROGRESS,
          rejectNewSupplement: RequestState.IN_PROGRESS,
          setPaid: [
            {
              target: RequestState.PAID,
              cond: (context) => !isFmcRequest(context),
            },
            {
              target: RequestState.INVOICE_APPROVED,
              cond: "isFmcRequest",
            },
          ],
          updateShopInvoice: RequestState.IN_SHOP,
          assignShop: {
            target: RequestState.IN_SHOP,
            cond: (context) => !isSaaS(context),
          },
        },
      },
      inProgress: {
        on: {
          awaitingPayment: RequestState.AWAITING_PAYMENT,
          inShopAwaitingApproval: RequestState.IN_SHOP_AWAITING_APPROVAL,
          shareEstimate: RequestState.IN_SHOP_AWAITING_APPROVAL,
          inShop: RequestState.IN_SHOP,
          paid: RequestState.PAID,
          addNewSupplement: RequestState.IN_SHOP,
          setWorkCompleted: RequestState.IN_PROGRESS,
          setInShop: RequestState.IN_SHOP,
          addPartsUpdate: RequestState.IN_PROGRESS,
          updateEcd: RequestState.IN_PROGRESS,
          rejectNewSupplement: RequestState.IN_PROGRESS,
          acceptNewSupplement: RequestState.IN_PROGRESS,
          setPaid: [
            {
              target: RequestState.PAID,
              cond: (context) => !isFmcRequest(context),
            },
            {
              target: RequestState.INVOICE_APPROVED,
              cond: "isFmcRequest",
            },
          ],
          updateShopInvoice: RequestState.IN_PROGRESS,
          assignShop: RequestState.IN_PROGRESS,
        },
      },
      awaitingPayment: {
        on: {
          paid: [
            {
              target: RequestState.PAID,
              cond: (context) => !isFmcRequest(context),
            },
          ],
          addPartsUpdate: RequestState.AWAITING_PAYMENT,
          inShopAwaitingApproval: RequestState.IN_SHOP_AWAITING_APPROVAL,
          shareEstimate: RequestState.IN_SHOP_AWAITING_APPROVAL,
          inProgress: RequestState.IN_PROGRESS,
          awaitingPayment: RequestState.AWAITING_PAYMENT,
          invoiceApproved: [
            {
              target: RequestState.INVOICE_APPROVED,
              cond: "isFmcRequest",
            },
          ],
          setPaid: [
            {
              target: RequestState.PAID,
              cond: (context) => !isFmcRequest(context),
            },
            {
              target: RequestState.INVOICE_APPROVED,
              cond: "isFmcRequest",
            },
          ],
          updateShopInvoice: RequestState.AWAITING_PAYMENT,
          assignShop: {
            target: RequestState.AWAITING_PAYMENT,
            cond: (context) => !isSaaS(context),
          },
          approveInvoice: [
            {
              target: RequestState.INSURANCE_INVOICE_APPROVED,
              cond: "isInsurance",
            },
            {
              target: RequestState.INVOICE_APPROVED,
              cond: "isFmcRequest",
            },
          ],
          insuranceInvoiceApproved: {
            target: RequestState.INSURANCE_INVOICE_APPROVED,
            cond: "isInsurance",
          },
        },
      },
      insuranceInvoiceApproved: {
        on: {
          paid: RequestState.PAID,
        },
      },
      invoiceApproved: {
        on: {
          scheduledDropoff: RequestState.SCHEDULED_DROPOFF,
          scheduleDropoff: RequestState.SCHEDULED_DROPOFF,
          updateShopInvoice: RequestState.INVOICE_APPROVED,
          assignShop: {
            target: RequestState.INVOICE_APPROVED,
            cond: (context) => !isSaaS(context),
          },
        },
      },
      paid: {
        on: {
          scheduledDropoff: RequestState.SCHEDULED_DROPOFF,
          scheduleDropoff: RequestState.SCHEDULED_DROPOFF,
          updateShopInvoice: RequestState.PAID,
          assignShop: {
            target: RequestState.PAID,
            cond: (context) => !isSaaS(context),
          },
        },
      },
      scheduledDropoff: {
        on: {
          deliveryInProgress: RequestState.DELIVERY_IN_PROGRESS,
          paid: RequestState.PAID,
          inShop: RequestState.IN_SHOP,
          scheduleDropoff: RequestState.SCHEDULED_DROPOFF,
          updateShopInvoice: RequestState.SCHEDULED_DROPOFF,
          closed: {
            target: RequestState.CLOSED,
            cond: (context) => isSaaS(context),
          },
          setDeliveryInProgress: RequestState.DELIVERY_IN_PROGRESS,
        },
      },
      deliveryInProgress: {
        on: {
          scheduledDropoff: RequestState.SCHEDULED_DROPOFF,
          inShop: RequestState.IN_SHOP,
          scheduleDropoff: RequestState.SCHEDULED_DROPOFF,
          updateShopInvoice: RequestState.DELIVERY_IN_PROGRESS,
          closeRequest: RequestState.CLOSED,
          closed: RequestState.CLOSED,
        },
      },
      closed: {
        on: {
          new: RequestState.NEW,
          updateShopInvoice: RequestState.CLOSED,
        },
      },
      lost: {
        on: {
          new: RequestState.NEW,
        },
      },
    },
  };

export const RequestFSM = createMachine(config, {
  guards: {
    isShopAssigned,
    isFmcRequest,
    isPickupScheduled,
    isSaaS,
    wasEstimateSkipped,
    isInsurance,
    isAdditionalWorkRecommended,
  },
});

export function listAvailableTransitions(request: RequestContext) {
  const fsm = RequestFSM.withContext(request);

  if (!fsm.states || !request.status || !fsm.states[request.status]) {
    console.error("Invalid request status", request);
    return Object.values(RequestState);
  }

  const transitions = fsm.states[request.status].transitions
    .filter(
      (transition) => !transition.cond || transition.cond?.predicate(request)
    )
    .map((event) => event.eventType as RequestOption);

  return transitions;
}

/// Returns true if any of the transitions are available for the current request
export function checkTransitions(
  request: RequestContext,
  transitions: RequestOption[]
) {
  const availableTransitions = listAvailableTransitions(request);
  return Boolean(
    transitions.find((transition) => availableTransitions.includes(transition))
  );
}

// Convenience function for checking a single transition
export const checkTransition = (
  request: RequestContext,
  transition: RequestOption
) => checkTransitions(request, [transition]);

export function applyRequestAction(
  request: RequestContext,
  transition: RequestAction
) {
  const fsm = RequestFSM.withContext(request);
  if (!checkTransition(request, transition)) {
    const err = new InvalidTransitionError(request, transition);
    console.warn(err.message);
    throw err;
  }
  const status = fsm.transition(request.status, transition)
    .value as RequestState;
  return { ...request, status };
}

export function applyRequestStatusChange(
  request: RequestContext,
  transition: RequestState
) {
  const fsm = RequestFSM.withContext(request);
  const status = fsm.transition(request.status, transition).value as string;
  if (status != transition) {
    const err = new InvalidTransitionError(request, transition);
    console.warn(err.message);
    throw err;
  }
  return { ...request, status };
}
