import { useEffect, useState } from "react";

import assert from "assert";

import MagicBell, {
  FloatingNotificationInbox,
} from "@magicbell/magicbell-react";
import { Box, Stack } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";

import SelectOption from "shared/components/navbar/NavbarLink";
import { getCurrentUser } from "shared/generated/clients/auth.client";
import { useAuth } from "shared/providers/AuthProvider";

import { Fleet } from "../../../../../backend/src/fleets/fleet.entity";
import { useFleets } from "../../providers/FleetProvider";
import AddFleetModal from "../modals/AddFleetModal";

import AccountProfile from "./AccountProfile";
import AccountSwitcher from "./AccountSwitcher";
import InsuranceTabs from "./InsuranceTabs";

const DesktopViewAuthenticated: React.FunctionComponent<{
  currentPath: string;
}> = ({ currentPath }) => {
  const {
    fleets,
    currentFleetIdAsNumber,
    currentFleetId: currentQueryParams,
    setCurrentFleetId,
    refetch,
  } = useFleets();
  const isSingleFleetId = currentFleetIdAsNumber !== -1;
  const router = useRouter();
  const { logout, currentUser } = useAuth();
  const { allFleets } = useFlags();
  const [showAddFleetModal, setShowAddFleetModal] = useState(false);
  const [serviceupUser, setServiceupUser] = useState<
    | {
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        avatarUrl: string | null;
      }
    | undefined
  >();

  assert(
    process.env.NEXT_PUBLIC_MAGIC_BELL_API_KEY,
    "Magic Bell API key is missing"
  );

  const onAddFleetSubmit = async (fleet: Fleet) => {
    if (!fleet) return;
    setShowAddFleetModal(false);
    await setCurrentFleetId(fleet.id);
    await refetch();
  };

  const isInsurance = process.env.NEXT_PUBLIC_PROJECT_NAME === "insurance";

  useEffect(() => {
    const getUserInfo = async () => {
      const response = await getCurrentUser({});
      if (response.error) {
        return;
      }

      setServiceupUser(response.data);
    };

    getUserInfo();
  }, [currentUser]);

  if (!currentUser?.email || !serviceupUser) return null;

  const tabs = [
    { path: "/request", label: "Orders" },
    { path: "/vehicles", label: "Vehicles" },
    { path: "/history", label: "History" },
    { path: "/insights", label: "Insights" },
    { path: "/shops", label: "Shops" },
    { path: "/settings", label: "Settings" },
  ];

  return (
    <>
      {isInsurance ? (
        <InsuranceTabs
          serviceupUser={serviceupUser}
          currentPath={currentPath}
        />
      ) : (
        <Stack
          display={{ xs: "none", lg: "flex" }}
          justifyContent={"space-between"}
          width={"100%"}
          direction={"row"}
          gap={3}
        >
          <Stack spacing={5} direction={"row"} alignItems="center">
            {tabs.map(({ path, label }) => {
              const params = router.asPath.split("?")[1];
              return (
                <SelectOption
                  key={path}
                  currentPath={currentPath}
                  href={`${path}?${params}`}
                  text={label}
                  disabled={
                    allFleets && !isSingleFleetId
                      ? ![
                          "Orders",
                          "Vehicles",
                          "History",
                          "Insights",
                          "Shops",
                        ].includes(label)
                      : false
                  }
                  disabledMessage="Coming soon in All Fleets view."
                />
              );
            })}
          </Stack>
          <Box display="flex" alignItems="center" flexGrow={1} gap={2}>
            {!!fleets?.length && (
              <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                <AccountSwitcher
                  fleets={fleets.filter((fleet) => !fleet.isInsuranceFleet)}
                  setShowModal={setShowAddFleetModal}
                />
              </Box>
            )}
            <Box>
              <MagicBell
                apiKey={process.env.NEXT_PUBLIC_MAGIC_BELL_API_KEY}
                userEmail={currentUser.email}
                userExternalId={currentUser?.uid}
                locale="en"
                theme={{
                  footer: {
                    backgroundColor: "#FFFFFF",
                    textColor: "#FFFFFF",
                  },
                }}
              >
                {(props) => (
                  <FloatingNotificationInbox
                    width={400}
                    height={500}
                    notificationPreferencesEnabled={false}
                    onNotificationClick={(notification) => {
                      router.push({
                        pathname: `/request/${notification.topic}`,
                        query: { fleetId: currentQueryParams },
                      });
                    }}
                    {...props}
                  />
                )}
              </MagicBell>
            </Box>
            <Box>
              <AccountProfile serviceupUser={serviceupUser} logout={logout} />
            </Box>
          </Box>
          <AddFleetModal
            open={showAddFleetModal}
            onClose={() => setShowAddFleetModal(false)}
            onAddFleetSuccess={onAddFleetSubmit}
          />
        </Stack>
      )}
    </>
  );
};

export default DesktopViewAuthenticated;
