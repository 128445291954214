import { TextField, TextFieldProps, TextFieldVariants } from "@mui/material";
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
} from "react-hook-form";

type FormatterFunction = (value: string) => string;

export type ControlledTextFieldProps<
  TVariant extends TextFieldVariants = TextFieldVariants,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps<TVariant> &
  Pick<ControllerProps<TFieldValues, TName>, "control" | "name" | "rules"> & {
    formatter?: FormatterFunction;
  };

// Default formatter doesn't alter the value
export const defaultFormatter: FormatterFunction = (value) => value;

export default function ControlledTextField<
  TVariant extends TextFieldVariants = TextFieldVariants,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  rules,
  formatter = defaultFormatter,
  inputProps,
  InputProps,
  ...rest
}: ControlledTextFieldProps<TVariant, TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...rest}
          {...field}
          value={formatter(field.value || "")}
          onChange={(e) => {
            const formattedValue = formatter(e.target.value);
            if (rest.onChange) rest.onChange(e);
            field.onChange(formattedValue);
          }}
          error={Boolean(error)}
          helperText={rest?.helperText || error?.message}
          inputProps={inputProps}
          // TODO: inputProps and InputProps are not the same. Convert current usages of inputProps to InputProps, then remove this.
          InputProps={
            {
              ...inputProps,
              ...InputProps,
            } as TextFieldProps["InputProps"]
          }
        />
      )}
    />
  );
}
