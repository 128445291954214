import { ReactNode } from "react";

import { Tooltip } from "@mui/material";
import MuiLink from "@mui/material/Link";
import { LinkProps as NextLinkProps } from "next/dist/client/link";
import Link from "next/link";

type StyledLinkProps = NextLinkProps & {
  children: ReactNode;
  LinkProps?: React.ComponentProps<typeof MuiLink>;
  disabled?: boolean;
  disabledMessage?: string;
};

export default function StyledLink({
  children,
  LinkProps,
  disabled,
  disabledMessage,
  ...nextLinkProps
}: StyledLinkProps) {
  return (
    <>
      {disabled ? (
        <Tooltip title={disabledMessage}>
          <MuiLink
            component="span"
            sx={{
              color: "lightgrey",
              "&:hover": {
                textDecoration: "none",
                cursor: "default",
              },
              ...LinkProps?.sx,
            }}
          >
            {children}
          </MuiLink>
        </Tooltip>
      ) : (
        <Link {...nextLinkProps} passHref>
          <MuiLink component="span" {...LinkProps}>
            {children}
          </MuiLink>
        </Link>
      )}
    </>
  );
}
