import { useTheme } from "@mui/material";

import StyledLink from "../StyledLink";

const NavbarLink: React.FunctionComponent<{
  href: string;
  text: string;
  currentPath: string;
  disabled?: boolean;
  disabledMessage?: string;
}> = ({ href, text, currentPath, disabled = false, disabledMessage }) => {
  const isActive = href.split("?")[0] === currentPath.split("?")[0];
  const theme = useTheme();
  const borderBottom = isActive
    ? `4px solid ${theme.palette.accent1.main}`
    : "none";

  return (
    <StyledLink
      href={href}
      LinkProps={{
        sx: {
          fontSize: 18,
          textDecoration: "none",
          borderBottom,
          color: disabled ? "lightgrey" : "secondary.main",
        },
      }}
      disabled={disabled}
      disabledMessage={disabledMessage}
    >
      {text}
    </StyledLink>
  );
};

export default NavbarLink;
