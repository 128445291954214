import {
  alpha,
  createTheme,
  darken,
  lighten,
  SxProps,
  Theme,
} from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";

import "@mui/material/Button";

import { Colors, DarkModeColors } from "./colors";

export type Mode = "light" | "dark";

declare module "@mui/material/styles" {
  interface Palette {
    accent1: Palette["primary"];
    accent2: Palette["primary"];
    accent3: Palette["primary"];
    accent4: Palette["primary"];
  }

  interface PaletteOptions {
    accent1: PaletteOptions["primary"];
    accent2: PaletteOptions["primary"];
    accent3: PaletteOptions["primary"];
    accent4: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    "responsive-icon": true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    white: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    accent1: true;
    accent2: true;
    accent3: true;
    accent4: true;
  }

  interface SvgIconPropsSizeOverrides {
    teeny: true;
  }
}

const fontFamily = ["Sora", '"Helvetica Neue"', "Arial", "sans-serif"].join(
  ","
);

const baseTheme = createTheme({
  typography: {
    h1: {
      fontSize: 96,
      fontWeight: "bolder",
      letterSpacing: "-1.5px",
    },
    h2: {
      fontSize: 60,
      fontWeight: "bolder",
      letterSpacing: "-0.5px",
    },
    h3: {
      fontSize: 48,
      fontWeight: "bolder",
      letterSpacing: 0,
    },
    h4: {
      fontSize: 34,
      fontWeight: "bold",
      letterSpacing: "0.25px",
    },
    h5: {
      fontSize: 24,
      fontWeight: "bold",
      letterSpacing: 0,
    },
    h6: {
      fontSize: 20,
      fontWeight: "bold",
      letterSpacing: "0.15px",
    },
    body1: {
      fontSize: 16,
      letterSpacing: "0.15px",
    },
    body2: {
      fontSize: 14,
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: "0.1px",
    },
    fontFamily,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            [theme.breakpoints.down("md")]: {
              paddingLeft: 0,
              paddingRight: 0,
            },
          };
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            "&.Mui-readOnly": {
              cursor: "not-allowed",
              pointerEvents: "none",
              color: theme.palette.text.disabled,
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px dotted",
              },
            },
          };
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            "&.Mui-disabled": {
              color: theme.palette.text.secondary,
            },
          };
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { color: "white" },
          style: {
            color: "#ffffff",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          let props: Omit<SxProps<Theme>, "variants"> = {
            borderRadius: "24px",
            textTransform: "none",
            letterSpacing: 0,
          };
          let hoverProps: Omit<SxProps<Theme>, "variants"> | undefined =
            undefined;

          if (
            ownerState.variant === "text" &&
            (!ownerState.color || ownerState.color === "primary")
          ) {
            props = {
              ...props,
              color: theme.palette.primary.main,
            };
          }

          if (
            ownerState.variant === "contained" &&
            (!ownerState.color || ownerState.color === "primary")
          ) {
            props = {
              ...props,
              color: theme.palette.getContrastText(theme.palette.accent1.main),
              backgroundColor: theme.palette.accent1.main,
            };
            hoverProps = {
              backgroundColor: darken(theme.palette.accent1.main, 0.3),
            };
          } else if (
            ownerState.variant === "outlined" &&
            (!ownerState.color || ownerState.color === "primary")
          ) {
            props = {
              ...props,
              color: theme.palette.secondary.main,
              borderColor: theme.palette.secondary.main,
            };

            if (theme.palette.mode === "light") {
              hoverProps = {
                color: theme.palette.secondary.main,
                borderColor: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.main, 0.9),
              };
            } else {
              hoverProps = {
                borderColor: lighten(theme.palette.secondary.main, 0.3),
              };
            }
          }

          return { ...props, "&:hover": hoverProps };
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "&.Mui-checked, &.MuiCheckbox-indeterminate": {
            color: Colors.Purple,
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        variant: "body2",
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 700,
          textDecoration: "underline",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body2: "p",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: 12,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.action.disabled,
          borderRadius: "8px",
        }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor:
            theme.palette.mode === "light"
              ? theme.palette.secondary.main
              : theme.palette.background.paper,
        }),
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        componentsProps: {
          paper: {
            elevation: 24,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }: { theme: Omit<Theme, "components"> }) => ({
          borderRadius: theme.shape.borderRadius,
          fontSize: theme.typography.body2.fontSize,
          padding: 4,
          boxShadow: theme.shadows[1],
          maxWidth: theme.breakpoints.values.sm,
        }),
      },
      defaultProps: {
        arrow: true,
      },
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          maxWidth: 170,
          width: "fit-content",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: ({ theme }) => ({
          color: Colors.White,
          backgroundColor: theme.palette.error.main,
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          "&:last-of-type": {
            borderRadius: "8px",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          "&.MuiAvatar-rounded": {
            borderRadius: "8px",
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          "&.MuiSvgIcon-fontSizeTeeny": {
            fontSize: "0.7rem",
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          "& .MuiBreadcrumbs-li": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            flex: 1,
          },
          "& .MuiBreadcrumbs-li:not(:last-of-type)": {
            overflow: "visible",
            flex: "unset",
          },
        },
      },
    },
    MuiImageListItemBar: {
      styleOverrides: {
        title: {
          fontSize: 12,
        },
      },
    },
  },
});

export const lightTheme = createTheme(baseTheme, {
  palette: {
    mode: "light",
    primary: {
      main: Colors.Purple,
    },
    secondary: {
      main: Colors.Navy,
    },
    warning: {
      main: Colors.Orange,
    },
    info: {
      main: Colors.Blue,
    },
    error: {
      main: Colors.Red,
    },
    success: {
      main: Colors.Green,
    },
    text: {
      primary: "#000000DE",
      secondary: "#00000099",
      disabled: "#00000061",
    },
    grey: {
      50: Colors.Grey,
      100: "lightgray",
    },
    background: {
      default: Colors.White,
      paper: Colors.OffWhite,
      grey: Colors.WhiteSmoke,
    },
    accent1: {
      main: Colors.Yellow,
    },
    accent2: {
      main: Colors.Salmon,
    },
    accent3: {
      main: Colors.LightBlue,
    },
    accent4: {
      main: Colors.Portage,
    },
    white: {
      main: Colors.White,
    },
  },
});

export const darkTheme = createTheme(baseTheme, {
  palette: {
    mode: "dark",
    primary: {
      main: Colors.LightPurple,
    },
    secondary: {
      main: Colors.Steel,
    },
    warning: {
      main: Colors.LightOrange,
    },
    info: {
      main: "#29B6F6",
      secondary: "#0288D1",
    },
    error: {
      main: Colors.LightRed,
    },
    success: {
      main: "#66BB6A",
    },
    grey: {
      50: Colors.Grey,
      100: "lightgray",
      200: "grey[800]",
    },
    text: {
      primary: "#fff",
      secondary: "#FFFFFFB2",
      background: "#FFFFFF80",
    },
    background: {
      default: DarkModeColors.Black,
      paper: darken(DarkModeColors.Black, 0.3),
      grey: lighten(DarkModeColors.Black, 0.05),
    },
    divider: alpha(Colors.Steel, 0.2),
    action: {
      active: "#ffffff",
      disabled: Colors.Steel,
    },
    accent1: {
      main: Colors.Yellow,
    },
    accent2: {
      main: Colors.Salmon,
    },
    accent3: {
      main: Colors.LightBlue,
    },
    accent4: {
      main: Colors.Portage,
    },
    white: {
      main: Colors.White,
    },
  },
});
