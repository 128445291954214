import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { FirebaseError } from "firebase/app";
import {
  sendPasswordResetEmail,
  signInWithCustomToken,
  User,
} from "firebase/auth";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";

import { auth } from "shared/firebaseConfig/firebase";
import {
  createAdvisorToken,
  createFleetToken,
  createShopToken,
} from "shared/generated/clients/auth.client";
import { loginWithInvite } from "shared/generated/clients/fleet.client";

type LoginResponse =
  | { data?: { token: string }; error?: never }
  | { error?: { message: string | null; statusCode?: number }; data?: never };

type AcceptFleetInviteForm = {
  invite: string;
  signature: string;
  email: string;
  fleetId: number;
  inviteId: number;
  phone: string;
  isInsurance?: boolean;
  password: string;
};

interface AuthContextType {
  currentUser: User | null;
  loginShops: (email: string, password: string) => Promise<LoginResponse>;
  loginAdvisors: (email: string, password: string) => Promise<LoginResponse>;
  loginCustomers: (email: string, password: string) => Promise<LoginResponse>;
  loginCustomersWithInvite: (
    email: string,
    password: string,
    inviteForm: AcceptFleetInviteForm
  ) => Promise<LoginResponse>;
  resetPassword: (email: string) => Promise<void>;
  logout: () => Promise<void>;
  impersonateShopUser: (
    token: string
  ) => Promise<void | { error: { message: string } }>;
  loginCustomersWithToken: (
    token: string
  ) => Promise<void | { error: { message: string } }>;
  shopId: number | null;
}

const errorCodes: Record<string, string> = {
  "auth/user-not-found": "User not found",
  "auth/wrong-password": "Incorrect password",
  "auth/invalid-email": "Invalid email",
  "auth/email-already-in-use": "Email already in use",
  "auth/weak-password": "Password is too weak",
  "auth/too-many-requests": "Too many requests",
  "auth/user-disabled": "User disabled",
  "auth/requires-recent-login": "Requires recent login",
  "auth/user-token-expired": "User token expired",
  "auth/web-storage-unsupported": "Web storage unsupported",
  "auth/invalid-credential": "Invalid credential",
  "auth/operation-not-allowed": "Operation not allowed",
  "auth/unauthorized-domain": "Unauthorized domain",
  "auth/credential-already-in-use": "Credential already in use",
  "auth/popup-blocked": "Popup blocked",
  "auth/popup-closed-by-user": "Popup closed by user",
  "auth/unauthorized-continue-uri": "Unauthorized continue uri",
  "auth/invalid-custom-token": "Invalid custom token",
  "auth/invalid-verification-code": "Invalid verification code",
  "auth/invalid-verification-id": "Invalid verification ID",
  "auth/missing-verification-code": "Missing verification code",
  "auth/invalid-continue-uri": "Invalid continue uri",
  "auth/invalid-dynamic-link-domain": "Invalid dynamic link domain",
  "auth/app-not-authorized": "App not authorized",
  "auth/expired-action-code": "Expired action code",
  "auth/cancelled-popup-request": "Cancelled popup request",
  "auth/internal-error": "Internal error",
} as const;

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

interface AuthProviderProps {
  children: ReactNode;
  routeRequiresAuth: boolean;
  loginPath: string;
}

export function AuthProvider({
  children,
  routeRequiresAuth,
  loginPath,
}: AuthProviderProps) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [shopId, setShopId] = useState<number | null>(null);
  const ldClient = useLDClient();
  const router = useRouter();
  const isIdentified = useRef(false);

  function getPrettyErrorMessage(error: unknown): string {
    if (error instanceof FirebaseError) {
      return errorCodes[error.code] ?? "Unable to login";
    } else if (error instanceof Error) {
      return error.message;
    }
    return "Unable to login";
  }

  const impersonateShopUser = async (token: string) => {
    const { redirectTo } = router.query;
    const credential = await signInWithCustomToken(auth, token);

    if (credential.user === null) {
      return {
        error: { message: getPrettyErrorMessage(credential) },
      };
    }

    setCurrentUser(credential.user);

    const cred = await credential.user.getIdTokenResult();
    const claims: any = cred.claims["https://hasura.io/jwt/claims"];
    setShopId(claims["x-hasura-shop-id"] as any);

    router.push(redirectTo?.toString() ?? "/");
  };

  const loginShops = async (email: string, password: string) => {
    const shopResponse = await createShopToken({
      body: { email, password },
    });

    if (shopResponse.error) {
      return shopResponse;
    }

    await impersonateShopUser(shopResponse.data.token);

    return shopResponse;
  };

  const loginAdvisors = async (email: string, password: string) => {
    const advisorResponse = await createAdvisorToken({
      body: { email, password },
    });

    if (advisorResponse.error) {
      return advisorResponse;
    }

    const credential = await signInWithCustomToken(
      auth,
      advisorResponse.data.token
    );

    if (credential.user === null) {
      return {
        error: { message: getPrettyErrorMessage(credential) },
      };
    }

    setCurrentUser(credential.user);

    return advisorResponse;
  };

  const loginCustomersWithToken = async (token: string) => {
    const credential = await signInWithCustomToken(auth, token);

    if (credential.user === null) {
      return {
        error: { message: getPrettyErrorMessage(credential) },
      };
    }

    setCurrentUser(credential.user);
  };

  const loginCustomers = async (email: string, password: string) => {
    const customerResponse = await createFleetToken({
      body: { email, password },
    });

    if (customerResponse.error) {
      return customerResponse;
    }

    await loginCustomersWithToken(customerResponse.data.token);

    return customerResponse;
  };

  const loginCustomersWithInvite = async (
    email: string,
    password: string,
    inviteForm: AcceptFleetInviteForm
  ) => {
    const inviteResponse = await loginWithInvite({
      body: {
        ...inviteForm,
        email,
        password,
      },
    });

    if (inviteResponse.error) {
      return inviteResponse;
    }

    return await loginCustomers(email, password);
  };

  const logout = async () => {
    await auth.signOut();
    setCurrentUser(null);
  };

  const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(auth, email);
  };

  useEffect(() => {
    const handleAuthStateChanged = (user: User | null) => {
      setCurrentUser(user);

      if (user) {
        user.getIdTokenResult().then((token: any) => {
          const claims: any = token.claims["https://hasura.io/jwt/claims"];
          setShopId(claims["x-hasura-shop-id"] as any);
        });

        ldClient?.identify({
          key: user.uid,
          email: user.email ?? undefined,
          name: user.displayName ?? undefined,
        });
      } else if (!user && !routeRequiresAuth) {
        const url = {
          pathname: loginPath,
          query: router.asPath !== "/" ? { redirectTo: router.asPath } : {},
        };

        return router.push(url);
      }
    };

    return auth.onAuthStateChanged(handleAuthStateChanged);
  }, [routeRequiresAuth, isIdentified, ldClient]);

  const value = {
    currentUser,
    loginShops,
    loginAdvisors,
    loginCustomers,
    resetPassword,
    logout,
    shopId,
    impersonateShopUser,
    loginCustomersWithToken,
    loginCustomersWithInvite,
  };

  return (
    <AuthContext.Provider value={value}>
      {(routeRequiresAuth || currentUser) && children}
    </AuthContext.Provider>
  );
}
