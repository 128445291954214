import { Box, Toolbar } from "@mui/material";

import { AccountSettingsProvider } from "../../providers/AccountSettings/AccountSettingsProvider";
import { FleetProvider } from "../../providers/FleetProvider";
import { ServicesFlowProvider } from "../../providers/ServiceFlowProvider";
import { TagsProvider } from "../../providers/TagsProvider";
import { VehicleProvider } from "../../providers/VehicleProvider";
import { VehicleUploadStatusProvider } from "../../providers/VehicleUploadStatusProvider";
import Navbar from "../navbar/Navbar";

const Layout: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <AccountSettingsProvider>
      <FleetProvider>
        <TagsProvider>
          <ServicesFlowProvider>
            <VehicleProvider>
              <VehicleUploadStatusProvider>
                <Navbar />
                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                    overflow: "auto",
                    overscrollBehavior: "none",
                  }}
                >
                  <Toolbar />
                  {children}
                </Box>
              </VehicleUploadStatusProvider>
            </VehicleProvider>
          </ServicesFlowProvider>
        </TagsProvider>
      </FleetProvider>
    </AccountSettingsProvider>
  );
};

export default Layout;
